import axios from "axios";
import Chart, { ScriptableContext } from "chart.js/auto";
import { haversineDistance } from "../lib/haversine";
import colors from "../colors";
import mapboxgl from "mapbox-gl";
import booleanContains from "@turf/boolean-contains";
import bboxPolygon from "@turf/bbox-polygon";
import { point } from "@turf/helpers";
import nearestPointOnLine from "@turf/nearest-point-on-line";
// @ts-ignore
import { CrosshairPlugin } from "chartjs-plugin-crosshair";

export class EleChart {
  private chart?: Chart;
  private adventureId;
  private ctx;
  private data?: GeoJSON.Feature<GeoJSON.LineString>;
  private cumulativeD?: number[];

  private bounds?: mapboxgl.LngLatBounds;
  private markerIndex?: number;

  private options?: EleChartOptions;

  constructor(
    id: string,
    route: GeoJSON.Feature<GeoJSON.LineString>,
    options?: EleChartOptions
  ) {
    this.ctx = document.getElementById(id) as HTMLCanvasElement | null;
    if (!this.ctx) return;

    this.options = options;

    this.adventureId = Number.parseInt(this.ctx.dataset.adventureId!);

    this.getElevation(route);
  }

  mapElevations(cumulativeD: number[], elevations: number[]) {
    const roundedMinElevation = Math.floor(Math.min(...elevations) / 10) * 10;
    const roundedMaxElevation = Math.ceil(Math.max(...elevations) / 100) * 100;

    Chart.register(CrosshairPlugin);

    this.chart = new Chart(this.ctx!, {
      type: "line",
      data: {
        labels: cumulativeD.map((d) => d / 1000),
        datasets: [
          {
            label: "Elevation",
            data: elevations,
            pointBorderColor: "white",
            pointBackgroundColor: colors["pct-red"][600],
            pointBorderWidth: 3,
            borderWidth: 2,
            borderColor: colors.blue[600],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        animation: false,
        spanGaps: true,
        elements: {
          point: {
            radius: (ctx) => this.highlightMarker(ctx),
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              title: () => "",
              label: (tooltip) => {
                const elevation = elevations[tooltip.dataIndex].toFixed(0);
                const dist = (cumulativeD[tooltip.dataIndex] / 1000).toFixed(1);

                let tooltipText = [];

                tooltipText.push(`Distance: ${dist} km`);
                tooltipText.push(`Elevation: ${elevation} m`);

                return tooltipText;
              },
            },
          },
          // @ts-ignore
          crosshair: {
            line: {
              color: colors.orange[600],
              width: 3,
            },
            zoom: {
              enabled: false,
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            min: roundedMinElevation,
            max: roundedMaxElevation,
            position: "right",
            grid: {
              drawTicks: false,
            },
            ticks: {
              mirror: true,
              align: "end",
              callback: (val) => `${val} m`,
            },
          },
          x: {
            type: "linear",
            min: 0,
            max: Math.round(cumulativeD[cumulativeD.length - 1] / 1000),
            ticks: {
              align: "inner",
              maxRotation: 0,
              callback: (val) => `${val} km`,
            },
          },
        },
        onHover: (_event, elements) => {
          if (this.options?.onHover && this.data && elements[0]) {
            this.options.onHover(
              this.data.geometry.coordinates[elements[0].index]
            );
          }
        },
      },
      plugins: [
        {
          id: "mouseOutEvent",
          beforeEvent: (chart, args, pluginOptions) => {
            const event = args.event;
            if (event.type === "mouseout" && this.options?.onLeave) {
              this.options.onLeave();
            }
          },
        },
      ],
    });
  }

  highlightMarker(ctx: ScriptableContext<"line">) {
    if (this.markerIndex && ctx.dataIndex == this.markerIndex) {
      return 8;
    }

    return 0;
  }

  updateMarker(trackingPoint: TrackingPoint) {
    const pt = nearestPointOnLine(
      this.data!,
      point(trackingPoint.location.coordinates)
    );
    this.markerIndex = pt.properties.index;
    this.chart?.update();
  }

  async getElevation(route: GeoJSON.Feature<GeoJSON.LineString>) {
    this.data = route;

    const coords = this.data.geometry.coordinates;
    this.cumulativeD = this.cumalativeDistance(coords);
    const elevations = coords.map((coord) => coord[2]);

    this.mapElevations(this.cumulativeD, elevations);
  }

  cumalativeDistance(positions: GeoJSON.Position[]) {
    const distances = new Array(positions.length);

    for (let i = 0; i < distances.length; i++) {
      if (i == 0) {
        distances[i] = 0;
        continue;
      }
      distances[i] =
        haversineDistance(positions[i], positions[i - 1]) + distances[i - 1];
    }

    return distances;
  }

  updateBounds(bounds: mapboxgl.LngLatBounds) {
    if (!this.chart || !this.cumulativeD || !this.data) return;

    this.bounds = bounds;
    const bbox = bboxPolygon(bounds.toArray().flat() as GeoJSON.BBox);

    let xMin;
    let xMax;
    for (let i = 0; i < this.cumulativeD.length; i++) {
      if (booleanContains(bbox, point(this.data.geometry.coordinates[i]))) {
        xMin = this.cumulativeD![i];
        break;
      }
    }

    for (let i = this.cumulativeD.length - 1; i >= 0; i--) {
      if (booleanContains(bbox, point(this.data.geometry.coordinates[i]))) {
        xMax = this.cumulativeD![i];
        break;
      }
    }

    if (xMin && xMax && this.chart?.options?.scales) {
      this.chart.options.scales["x"]!.min = Math.round(xMin / 1000);
      this.chart.options.scales["x"]!.max = Math.round(xMax / 1000);
    }
    this.chart.update();
  }
}

export type EleChartOptions = {
  onHover?: (data: GeoJSON.Position) => void;
  onLeave?: () => void;
};
