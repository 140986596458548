import "./bootstrap";
import "./maps/index";
import "./clock";

import {
  Livewire,
  Alpine,
  // @ts-ignore
} from "../../vendor/livewire/livewire/dist/livewire.esm";
import { formatDateElements } from "./helpers";

document.addEventListener("alpine:init", () => {
  Alpine.data("toggle", () => ({
    ids: [],

    toggleAll(checked: boolean, namePrefix: string) {
      const checkboxes: NodeListOf<HTMLInputElement> =
        document.querySelectorAll(`[name^=${namePrefix}-]`);
      checkboxes.forEach((el) => {
        el.checked = checked;
        el.dispatchEvent(new Event("change"));
      });
    },

    enableGroupSelect(namePrefix: string) {
      let lastChecked: HTMLInputElement | null = null;
      const checkboxes: NodeListOf<HTMLInputElement> =
        document.querySelectorAll(`[name^=${namePrefix}-]`);

      //I'm attaching an index attribute because it's easy, but you could do this other ways...
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].setAttribute("data-index", `${i}`);
      }

      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].addEventListener("click", function (e) {
          if (lastChecked && e.shiftKey) {
            var i = parseInt(lastChecked.getAttribute("data-index")!);
            var j = parseInt(this.getAttribute("data-index")!);
            var check_or_uncheck = this.checked;

            var low = i;
            var high = j;
            if (i > j) {
              var low = j;
              var high = i;
            }

            for (var c = 0; c < checkboxes.length; c++) {
              if (low <= c && c <= high) {
                checkboxes[c].checked = check_or_uncheck;
                checkboxes[c].dispatchEvent(new Event("change"));
              }
            }
          }
          lastChecked = this;
        });
      }
    },
  }));
});

document.addEventListener("dates-loaded", () => formatDateElements());
formatDateElements();

Livewire.start();
